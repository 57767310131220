import { Injectable, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GraphqlHostService } from 'src/app/shared/services/graphql-host/graphql-host.service';
import { environment } from 'src/environments/environment';
import { GET_LANDING_PAGE_BANNER, LandingBannerCollection, LandingBannerResponse } from '../graphql/get-landing-banner.graphql.query';

@Injectable({
  providedIn: 'root'
})
export class LandingService {
  /*Dependency Injections*/
  private _graphqlHostService = inject(GraphqlHostService);

  /*Loading States*/
  isLoadingSubject = new BehaviorSubject<boolean>(false);
  isLoading$ = this.isLoadingSubject.asObservable();

  constructor() { }

  getLandingBanner(): Promise<LandingBannerCollection>{
    return new Promise((resolve, reject) => {
      this._graphqlHostService
        .getQueryResults(
          environment.graphqlServerName.contentful,
          GET_LANDING_PAGE_BANNER,
          {},
          null,
          'network-only'
        )
        .then((response: LandingBannerResponse) => {
          resolve(response.userPortalLandingPageBannerCollection);
        })
        .catch((error) => {
          this.isLoadingSubject.next(false);
          reject(error);
        });
    });
  }
}
