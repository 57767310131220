import { gql } from 'apollo-angular';

export const NEW_SUPPORT_CONSOLE_QUERY = gql`
query supportConsoleData(
  $searchCriteria: String!
  $searchField: String!
  $first: Int
  $after: String
  $last: Int
  $before: String
) {
  users: usersBy(
    first: $first
    after: $after
    last: $last
    before: $before
    searchField: $searchField
    searchCriteria: $searchCriteria
    order: { id: ASC }
  ) {
    pageInfo {
      startCursor
      hasPreviousPage
      hasNextPage
      endCursor
      __typename
    }
    edges {
      node {
        id
        firstName
        lastName
        email
        userName
        prefix
        suffix
        twoFactorEnabled
        emailConfirmed
        lockoutEnabled
        lockoutEnd
        phoneExtension
        phoneNumber
        primaryIdentifierNumber
        suffix
        isPasswordExpired
        usersInOrg {
          org {
            id
            orgName
            orgCode
            orgType
            dateExpire
            tpaRule
            orgsInServices {
              service {
                id
                name
                __typename
              }
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      cursor
      __typename
    }
    totalCount
    __typename
  }
}
`;


export interface PageInfo {
  __typename: string;
  startCursor: string;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  endCursor: string;
}

export interface Organization {
  __typename: string;
  orgName: string;
  orgCode: string;
  orgType: string;
  dateExpire: string;
  tpaRule: string;
  orgsInServices: OrgInService[];
}

export interface OrgInService {
  service: ApplicationService;
}

export interface ApplicationService {
  id: string;
  name: string;
}

export interface UserInOrganization {
  __typename: string;
  org: Organization;
}

export interface UserView {
  __typename?: string;
  id: string;
  firstName: string | null;
  lastName: string;
  email: string;
  userName: string;
  prefix: string;
  suffix: string;
  twoFactorEnabled: boolean;
  emailConfirmed: boolean;
  lockoutEnabled: boolean;
  lockoutEnd?: Date;
  phoneExtension: string;
  phoneNumber: string;
  primaryIdentifierNumber: string;
  usersInOrg: UserInOrganization[];
}

export interface UsersEdge {
  __typename?: string;
  node: UserView;
  cursor: string;
}

export interface UserData {
  __typename: string;
  pageInfo: PageInfo;
  edges: UsersEdge[];
  totalCount: number;
}

export interface UsersRequestResponse {
  users: UserData;
}

export interface PaginationVars {
  first: number | null;
  after: string | null;
  before: string | null;
  last: number | null;
}

export interface PaginationState {
  startCursor: string | null;
  endCursor: string | null;
  totalElements: number | null;
  pageSize?: number | null;
  currentPage?: number | null;
}

export interface SupportConsoleQueryVars {
  first: number | null;
  after: string | null;
  last: number | null;
  before: string | null;
  searchCriteria?: string | null;
  searchField: string | null;
}


/***************************************************************************************************************
  This query has been deprecated due to bug #128478. However,
  I'll keep it here as it serves as a valuable example of how we can construct a dynamic 'WHERE' clause
  within a GraphQL query, showcasing additional features.
****************************************************************************************************************/

// export const BUILD_SUPPORT_CONSOLE_QUERY = (conditionals: string) => {
// GraphQL query string for support console data
// Dynamic WHERE clauses based on conditionals
//   const SUPPORT_CONSOLE_USERS = `
//     query supportConsoleData(
//       $searchCriteria: String
//       $first: Int
//       $after: String
//       $last: Int
//       $before: String
//     ) {
//       users(
//         first: $first
//         after: $after
//         last: $last
//         before: $before
//         where: {
//           ${conditionals}
//         }
//         order: { id: ASC }
//       ) {
//         pageInfo {
//           startCursor
//           hasPreviousPage
//           hasNextPage
//           endCursor
//           __typename
//         }
//         edges {
//           node {
//             id
//             firstName
//             lastName
//             email
//             userName
//             prefix
//             suffix
//             twoFactorEnabled
//             emailConfirmed
//             lockoutEnabled
//             lockoutEnd
//             phoneExtension
//             phoneNumber
//             primaryIdentifierNumber
//             suffix
//             usersInOrg {
//               org {
//                 id
//                 orgName
//                 orgCode
//                 orgType
//                 dateExpire
//                 tpaRule
//                 orgsInServices {
//                     service {
//                         id
//                         name
//                     }
//                 }
//                 __typename
//               }
//               __typename
//             }
//             __typename
//           }
//           cursor
//           __typename
//         }
//         totalCount
//         __typename
//       }
//     }`;

//   return gql`${SUPPORT_CONSOLE_USERS}`;
// };