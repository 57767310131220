import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { AzureService } from 'src/app/core/services/azure.service';
import { environment } from 'src/environments/environment';

export const supportConsoleDataResolver: ResolveFn<boolean> = (route, state) => {
  const azureService = inject(AzureService);
  let requestSuccess: boolean = false;

  /*
   * Temporary check for development environment only.
   * This condition is in place until the email service is replicated in other environments.
   * Once the email service is available in staging/production, this check can be removed 
   * or adjusted to include those environments as well. /* 
   **/
   /* istanbul ignore next */
  if (environment.env === 'dev' || environment.env === 'local') {
    if (azureService.emailCredentialsValue) {
      return true;
    }

    azureService.getAzureClientEmailCredentials().subscribe({
      next: (response) => {
        requestSuccess = response;
      },
      error: () => {
        requestSuccess = false;
      }
    });

    return requestSuccess;
  } else {
    return true;
  }


};
