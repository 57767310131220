import { InjectionToken } from "@angular/core";
import { environment } from "src/environments/environment";

// for  under building features
export const isAllowedEnv = (env: string = environment.env): boolean => {
    const allowedEnvironments = ['local', 'dev', 'test']
    return allowedEnvironments.some(allowedEnv => allowedEnv === env);
}

// custom environment guard
/*istanbul ignore next*/
export const ALLOWED_ENVIRONMENTS_GUARD = new InjectionToken('my_guard', {
    providedIn: 'root',
    factory: () => () => isAllowedEnv()
});


// for cso workflow
export const getTargetUrl = () => {
    const CsoWorkflowAllowedEnv = ['local', 'dev', 'prod']
    /*istanbul ignore next*/
    const targetUrl = CsoWorkflowAllowedEnv.includes(environment.env) ? '/cso/verify' : '/dashboard'
    return targetUrl
}