import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, catchError, lastValueFrom, Observable, of, switchMap, tap, throwError } from 'rxjs';
import { BUILD_SEND_EMAIL_MUTATION, CreateSingleTemplateEmailResponse, EmailInputData, EmailTokenResponse, SEND_NOTIFICATION_EMAIL, TemplateEmailData } from '../../graphql/messaging-email.mutation';
import { environment } from 'src/environments/environment';
import { GraphqlHostService } from '../graphql-host/graphql-host.service';

@Injectable({
  providedIn: 'root'
})
export class MessagingEmailService {

  private readonly _http = inject(HttpClient);
  private readonly _graphqlHostService = inject(GraphqlHostService);
  private EMAIL_TOKEN_URL = environment.authentication.silentRenewUrl;
  private emailToken$ = new BehaviorSubject<EmailTokenResponse | null>(null);

  constructor() { }


  sendNotificationEmail(emailInputData: EmailInputData, mutationParams: string): Promise<TemplateEmailData> {
    const vars = emailInputData;
    const MUTATION = BUILD_SEND_EMAIL_MUTATION(mutationParams);
    return new Promise((resolve, reject) => {
      this._graphqlHostService
        .getMutationResults(
          environment.graphqlServerName.email,
          MUTATION,
          vars,
        )
        .then((response: CreateSingleTemplateEmailResponse) => {
          resolve(response.createSingleTemplateEmail.data);
        })
        .catch((error: string) => {
          const gError = {
            origin: 'sendNotificationEmail',
            error,
          };
          reject(gError);
        });
    });
  }

  fetchEmailServiceToken(clientId: string, clientSecret: string): Promise<EmailTokenResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    const body = new HttpParams()
      .set('grant_type', 'client_credentials')
      .set('client_id', clientId)
      .set('client_secret', clientSecret)
      .set('scope', 'MessengingService');

    const originalObservable = this._http.post<EmailTokenResponse>(this.EMAIL_TOKEN_URL, body.toString(), { headers })
      .pipe(
        tap((response) => this.setEmailToken(response)),
        catchError((error) => {
          // Handle the error here or log it if needed
          console.error('Failed to fetch email token:', error);
          return throwError(() => error);
        })
      );

    return lastValueFrom(originalObservable).catch((error) => {
      // Handle the rejected promise here
      console.error('Promise rejection:', error);
      throw error; // Re-throw
    });
  }

  get emailTokenResponse$(): Observable<EmailTokenResponse | null> {
    return this.emailToken$.asObservable();
  }

  get emailTokenValue(): EmailTokenResponse | null {
    return this.emailToken$.value;
  }

  private setEmailToken(emailToken: EmailTokenResponse): void {
    this.emailToken$.next(emailToken);
  }

}
