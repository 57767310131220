export const cleanSessionStorage = (keys: string[]): void => {
    keys.forEach(key => {
        sessionStorage.removeItem(key);
    });
}

/*istanbul ignore next*/
export const saveEncryptedToSessionStorage = (key: string, value: string): void => {
    const encryptedValue = btoa(value);
    sessionStorage.setItem(key, encryptedValue);
};

export const retrieveDecryptedFromSessionStorage = (key: string): string | null => {
    const encryptedValue = sessionStorage.getItem(key);

    if (encryptedValue) {
        const decryptedValue = atob(encryptedValue);
        return decryptedValue;
    }

    return null;
};


export const cleanLocalStorage = (keys: string[]): void => {
    keys.forEach(key => {
        localStorage.removeItem(key);
    });
}

export const saveEncryptedToLocalStorage = (key: string, value: string): void => {
    const encryptedValue = btoa(value);
    localStorage.setItem(key, encryptedValue);
};

export const retrieveDecryptedFromLocalStorage = (key: string): string | null => {
    const encryptedValue = localStorage.getItem(key);

    /*istanbul ignore if*/
    if (encryptedValue) {
        const decryptedValue = atob(encryptedValue);
        return decryptedValue;
    }

    return null;
};

/*istanbul ignore next*/
export const verifySessionItem = (key: string): boolean => {
    return sessionStorage.getItem(key)!== null;
}