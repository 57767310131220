import { Component, inject, Input, model, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map, Observable, shareReplay, Subject, takeUntil } from 'rxjs';
import { MatDrawer } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/features/authentication/services/authentication.service';
import { UserPortalDialogService } from '../../services/dialog/user-portal-dialog.service';
import { FooterResponse } from '../footer/footer-interface';
import { MENU, MenuNodeElement } from '../../layouts/portal/menu';
import { PortalSharedService } from '../../services/portal-shared/portal-contentful.service';
import { GetUserDataByIDService } from '../../layouts/portal/services/get-user-data-by-id.service';
import { UserPersonalData } from 'src/app/features/dashboard/graphql/profile-info.query';
import { fadeInLeft, fadeOutLeft } from '../../animations/fade';
import { UserPortalNavigationService } from '../../services/user-portal-navigation/user-portal-navigation.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-drawer-menu',
  templateUrl: './drawer-menu.component.html',
  styleUrl: './drawer-menu.component.scss',
  encapsulation: ViewEncapsulation.None,
  animations: [
    fadeInLeft,
    fadeOutLeft,
  ]
})
export class DrawerMenuComponent implements OnInit, OnDestroy {

  private readonly breakpointObserver = inject(BreakpointObserver);
  private readonly _userPortalDialogService = inject(UserPortalDialogService);
  private readonly _authenticationService = inject(AuthenticationService);
  private readonly unsubscribe = new Subject<void>();
  private readonly router = inject(Router);
  private readonly _userPortalShareService = inject(PortalSharedService);
  private readonly _getUserByIdService = inject(GetUserDataByIDService);
  private readonly _userPortalNavigationService = inject(UserPortalNavigationService);


  @Input() menuItems: MENU[] = [];
  @ViewChild('drawer', { static: true }) drawer!: MatDrawer;
  MENU_ROUTES = model<MENU[] | []>([]);

  // Boolean variables
  hasErrorOccurred: boolean = false;
  isSecondDrawerOpen: boolean = false;

  // Strings
  lastItemSelected: string = '';
  currentNode: string = '';
  thirdLevelTitle: string = '';
  currentActive: string = '';
  editProfileUrl: string = environment.onboardingClaims.lastProfileChange;

  // Arrays
  selectedSubMenu: string[] = [
    'profile',
    'support',
    'default',
    'account-selector',
    'user-profile',
    'user-support',
    'mobile',
  ];
  notMobileAllowed: string[] = [
    'Authentication Controls',
    'Delete Users',
    'Feature Management',
    'Profile',
    'User Creator',
  ];

  // Objects and complex types
  footer!: FooterResponse[];
  secondLevelNode!: MenuNodeElement | null;
  selectedParentLabelNode!: string | null;
  userData: UserPersonalData | null = null;

  // Observables
  isServerError$: Observable<any> = new Observable();

  // Signals
  isNavigationAppOn = this._userPortalNavigationService.isNavigationAppOn;

  constructor(
  ) {
    this.selectedSubMenu = ['default'];
    // NOTE: Disabled this resolver until contenfult footer data will be available
    // this._route.data.pipe(
    //   takeUntil(this.unsubscribe)
    // ).subscribe({
    //   next: (data) => {
    //     if (data['footer'].footerCollection) {
    //       this.hasErrorOccurred = false;
    //       this.footer = data['footer'].footerCollection.items;
    //     } else {
    //       this.hasErrorOccurred = true;
    //       this.footer = data['footer'].data;
    //     }
    //   },
    //   error: (error) => { }
    // });

    this.getPersonalUserData();
    this.catchErrorRequestInTemplate();
  }


  ngOnInit(): void {
    this.changeStateDefaultDrawer();
    this.sidebarStatusHandler();
  }

  isActiveRoute(route: string): boolean {
    const isRouteActive = this.router.isActive(route, {
      paths: 'exact',
      queryParams: 'exact',
      fragment: 'exact',
      matrixParams: 'exact'
    });

    const hasNoSubmenu = !this.menuItems.some(
      menu => menu.route === route && menu.node
    );

    return isRouteActive && hasNoSubmenu;
  }

  isMobileScreen$: Observable<boolean> = this.breakpointObserver.observe('(max-width: 1140px)')
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  goToPanel(subMenuName: string, subMenuLabel?: string): void {
    this.lastItemSelected = this.selectedSubMenu[0];
    this.selectedSubMenu = [subMenuName];
    if (subMenuLabel) {
      this.thirdLevelTitle = subMenuLabel;
    }
  }

  goBackToMainMenu(isBackStep?: boolean): void {
    if (isBackStep) {
      this.goToPanel(this.lastItemSelected);
      this.lastItemSelected = 'default';
      return;
    }
    this.selectedSubMenu = ['default'];
  }

  changeStateDefaultDrawer(): void {
    this.drawer.openedChange.subscribe((opener: boolean) => {
      /* istanbul ignore next */
      if (opener) {
        switch (this.selectedSubMenu[0]) {
          case 'mobile':
            this.selectedSubMenu = ['mobile'];
            break;
          default:
            this.goBackToMainMenu();
            break;
        }
      }
    });
  }
  resetSecondaryDrawer(): void {
    this.selectedParentLabelNode = '';
    this.isSecondDrawerOpen = false;
  }


  /**
   * Handles the selection of a menu node and manages the state of the second-level drawer,
   * toggling the bold style of the parent node appropriately.
   * @param {MenuNodeElement} currentNode - The menu node that is being selected.
   * @param {string | null} parentNode - The label of the parent node.
   * @param {string} [name] - Optional name of the selected node, used to update the active node.
   */
  setCurrentNode(currentNode: MenuNodeElement, parentNode: string | null, name?: string): void {
    const isSameNode = this.secondLevelNode === currentNode;
    const isSameParent = this.selectedParentLabelNode === parentNode;

    if (isSameNode && this.isSecondDrawerOpen) {
      // If the same node is selected and the drawer is open, close it
      this.closeSecondLevelDrawer();
      // Remove the bold style for the parent node
      this.selectedParentLabelNode = null;
    } else if (isSameParent) {
      // If the same parent is selected but a different child, keep the bold style
      this.openSecondLevelDrawer(currentNode);
    } else {
      // Open the drawer for a new parent and set the bold style
      this.openSecondLevelDrawer(currentNode);
      this.selectedParentLabelNode = parentNode;
    }

    // Update the current active node name if provided
    if (name) {
      this.currentActive = name;
    }
  }

  /**
   * Opens the second-level drawer and sets the current node.
   * @param {MenuNodeElement} currentNode - The menu node to set as the active second-level node.
   */
  private openSecondLevelDrawer(currentNode: MenuNodeElement): void {
    this.isSecondDrawerOpen = true; // Set the drawer state to open
    this.secondLevelNode = currentNode; // Update the active second-level node
  }

  /**
   * Closes the second-level drawer and resets the current node.
   */
  private closeSecondLevelDrawer(): void {
    this.isSecondDrawerOpen = false; // Set the drawer state to closed
    this.secondLevelNode = null; // Reset the active second-level node
  }


  /**
  * Creates dynamically virtual routes for this component
  */
  sidebarStatusHandler(name?: string, isCloseClick?: boolean): void {
    if (isCloseClick) {
      this.isSecondDrawerOpen = false;
      this.secondLevelNode = null;
    }
    /* istanbul ignore next */
    if (this.MENU_ROUTES.length) {
      if (name) {
        this.currentActive = name;
      }

      if (!isCloseClick) {
        this.isSecondDrawerOpen = false;
      }
    }
  }

  /* istanbul ignore next */
  /**
   * Triggers the action to logout the user from the identity server
   */
  logout(): void {
    this._authenticationService.logout();
  }

  /**
   * Closes the secondary drawer and clears the selected node data.
   */
  closeSecondDrawer(): void {
    this.isSecondDrawerOpen = false;
    this.secondLevelNode = null;
  }

  getPersonalUserData(): void {
    this.userData = this._getUserByIdService.getUserPersonalDataValue;
  }

  catchErrorRequestInTemplate(): void {
    this.isServerError$ = this._userPortalShareService.isServerError$.pipe(
      takeUntil(this.unsubscribe)
    );
  }

  countUnReadNotifications(): number {
    return this._userPortalShareService.countUnreadNotifications();
  }

  countAllNotifications(): number {
    return this._userPortalShareService.countAllNotifications();
  }

  openNotify(value: boolean): void {
    this._userPortalShareService.setShowNotificationBannerValue(true);
    this._userPortalShareService.setShowAllNotificationValue(value);
  }

  /* istanbul ignore next */
  /**
   * Opens the Confirmation Dialog with the data a object that contains its content
   */
  openLogoutConfirmationDialog(): void {

    // Creates the Dialog and stores its reference
    const logoutConfirmationDialogRef = this._userPortalDialogService.userPortalDialog(
      'Are you sure you want to leave?',
      `
        <p style="padding: 0.57rem 0 0 0">
          You are logging out of all LCPtracker applications.
        </p>
      `,
      'Confirm',
      'Cancel'
    );

    // Listens to the dialog, so when it closes it does an action
    logoutConfirmationDialogRef.afterClosed().subscribe(value => {

      // If the value is truthy, the user confirmed the action
      if (value) {
        // Logs out
        this.logout();
      }
      // Else, the user canceled the action
    })

  }

  // Close the secondary navigation
  closeSecondNavigation(): void {
    this.isSecondDrawerOpen = false;
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.drawer.openedChange.next(false);
    this.drawer.openedChange.unsubscribe();
  }
}