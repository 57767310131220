import { Component, Input, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { ERROR_LIST_DATA, ErrorMessage } from './error-handle-const';
import { Clipboard } from '@angular/cdk/clipboard';
import { AuthenticationService } from 'src/app/features/authentication/services/authentication.service';

@Component({
  selector: 'error-handle',
  templateUrl: './error-handle.component.html',
  styleUrls: ['./error-handle.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ErrorHandleComponent implements OnInit {
  /*Dependency Injections Angular 14 Feature*/
  private clipboard = inject(Clipboard);
  private authenticationService = inject(AuthenticationService);

  @Input() errorCategoryCode: string = 'UNKNOWN_ERROR';
  reloadPage: boolean = false;
  catchErrorInfo: ErrorMessage = {
    errorName: '',
    errorTitle: '',
    errorMessage: '',
    errorCode: '',
  };

  ngOnInit(): void {
    this.assignErrorValues();
  }


  assignErrorValues(): void {
    const foundError = ERROR_LIST_DATA.find((error) => error.errorCode === this.errorCategoryCode);
    console.log('ERROR DATA: ', foundError)

    if (foundError) {
      this.catchErrorInfo.errorTitle = foundError.errorTitle;
      this.catchErrorInfo.errorName = foundError.errorName;
      this.catchErrorInfo.errorMessage = foundError.errorMessage;
      this.catchErrorInfo.errorCode = foundError.errorCode;
      this.catchErrorInfo.button = foundError.button;
    } else {
      // Handle the case when the error category is not found in ERROR_LIST.
      this.catchErrorInfo.errorTitle = 'Unknown Error Title';
      this.catchErrorInfo.errorName = 'Unknown Error Name';
      this.catchErrorInfo.errorMessage = 'Unknown Error Message';
      this.catchErrorInfo.errorCode = 'Unknown_Error_Code';
    }
  }

  retryAuth(): void {
    this.authenticationService.login();
  }

  /* istanbul ignore next */
  refreshContent(): void {
    this.reloadPage = true;
    window.location.reload();
  }

  copyToClipboard(value: string): void {
    this.clipboard.copy(value);
  }

  clickMethodBasedOnErrorCode(value: string): string {
    switch (value) {
      case 'Invalid Session Identifier':
        return 're-authenticate'

      case 'Missing Onboarding Information':
        return 're-authenticate'

      case 'Content Retrieval Error':
        return 'refresh'

      case 'Internal Service Error':
        return 'refresh'

      default:
        return 'Refresh'
    }
  }

  logOutMethodBasedOnErrorCode(value: string): boolean {
    switch (value) {
      case 'Invalid Session Identifier':
        return true;
      case 'Missing Onboarding Information':
        return true;

      case 'Content Retrieval Error':
        return false;

      case 'Internal Service Error':
        return false;

      default:
        return false;
    }
  }

  logOut():void{
    this.authenticationService.logout();
  }
}
