import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { UserPortalDialogService } from 'src/app/shared/services/dialog/user-portal-dialog.service';
import { StringResultModel } from '../../../graphql/mutations/recovery-code-user-by-id.query.graphql';
import { BoolResultModel } from '../../../graphql/mutations/reset-password-user-by-username.query.graphql';
import { UsersEdge } from '../../../graphql/support-console.queries.graphql';
import { UsersService } from '../../../services/users.service';
import { UsersComponent } from '../../users/users.component';
import { TwoFactorMethod } from '../../../graphql/two-factor-method.queries.graphql';

@Component({
  selector: 'app-actions-detail',
  templateUrl: './actions-detail.component.html',
  styleUrls: ['./actions-detail.component.scss']
})
export class ActionsDetailComponent implements OnInit {
  private _usersService = inject(UsersService);
  private _portalDialogService = inject(UserPortalDialogService);
  private _usersComponent = inject(UsersComponent);

  @Input() user!: UsersEdge;
  @Output() expandPanel: EventEmitter<any> = new EventEmitter<any>();

  enableEmailOtpButton: boolean = false;

  constructor( ) { }

  ngOnInit(): void {
    this.validateTwoFactorMethod(this.user.node.id);
  }

  lockedEnd(lockedEnd: any): boolean {
    const now = new Date();
    const apiDate = new Date(lockedEnd);
    return apiDate > now;
  }

  /**
   * Unlock user request method
   * @param userId
   * @param userName
   */
  unlockUser(userName: string, userId: string): void {
    const dialogRef = this._portalDialogService.userPortalDialog(
      'Unlock User',
      `<p> You are about to unlock <strong> ${userName} </strong> account, do you want to continue?</p>`,
      'Yes',
      'Cancel'
    );

    dialogRef.afterClosed().subscribe((response) => {
      if (!response) {
        return;
      }

      if (!userName) {
        return;
      }

      this.expandPanel.emit({ state: true, expandPanelAfterRequest: userId });
      this._usersService.unlockUser(userName).then(() => {
        this._portalDialogService.openUserPortalSnackBar('User account unlocked successfully');
        this._usersComponent.getUsers();
      }).catch(error => {
        this._portalDialogService.openUserPortalSnackBar(`Error in unlock user`);
      });
    })
  }
  
  /**
   * Recovery code request method
   * @param userId
   * @param userName
   * @param panelId
   */
  getRecoveryCode(userId: string, panelId: string, username: string): void {
    const dialogRef = this._portalDialogService.userPortalDialog(
      'Recovery code',
      `<p> Are you requesting a recovery code for <strong> ${username} </strong>?</p>`,
      'Yes',
      'Cancel'
    );

    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        this.expandPanel.emit({ state: true, expandPanelAfterRequest: userId });
        if (userId) {
          this._usersService
            .getRecoveryCode(userId)
            .then((recoveryCoderesponse: StringResultModel): void => {
              if (recoveryCoderesponse.data?.includes('No recovery codes available')) {
                this._portalDialogService.openUserPortalSnackBar(`${recoveryCoderesponse.data}`);
              } else {
                this._portalDialogService.userPortalDialog('Success', `<p> You have retrieved the user code: <strong> ${recoveryCoderesponse.data} </strong>. 
                Be sure to save this code before closing this window.</p>`,
                  'Close',
                  null)
              }
            })
            .catch((_error: string) => {
              console.log(_error);
              this._portalDialogService.openUserPortalSnackBar(`Error in recovery code for user`);
            });
        }
      }
    })
  }

  /**
   * Reset password request method
   * @param userName
   * @param panelId
   */
  resetPassword(userName: string, panelId: string): void {
    const dialogRef = this._portalDialogService.userPortalDialog(
      'Reset Password',
      `<p> Are you requesting a password reset link for <strong> ${userName} </strong>?</p>`,
      'Yes',
      'Cancel'
    );

    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        if (userName) {
          this._usersService
            .resetPassword(userName)
            .then((resetResponse: BoolResultModel): void => {
              this._portalDialogService.openUserPortalSnackBar(`Link to reset password successfully sent via email`);
            })
            .catch((_error: string) => {
              console.error(_error);
              this._portalDialogService.openUserPortalSnackBar(`Error in reset password for user`);
            });
        }
      }
    })
  }

  closeSessions(userId: string, userName: string): void {
    const dialogRef = this._portalDialogService.userPortalDialog(
      'Close Sessions',
      `<p> You are about to close all sessions of <strong> ${userName} </strong> account, do you want to continue?</p>`,
      'Yes',
      'Cancel'
    );

    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        this._usersService._loading.set(true);
        if (userId) {
          this._usersComponent.expandPanelAfterRequest = userId;
          this._usersService.deleteActiveSessionsByUserId(userId).subscribe({
            next: () => {
              this._portalDialogService.openUserPortalSnackBar('The sessions have been closed');
            },
            error: () => {
              this._portalDialogService.openUserPortalSnackBar(`Error closing sessions for the user selected`);
            },
            complete: () => {
              this._usersService._loading.set(false);
            },
          });
        }
      }
    })
  }

  restartOnboarding(userName: string, userId: string): void {
    const dialogRef = this._portalDialogService.userPortalDialog(
      'Restart Onboarding',
      `<p> You are about to restart the onboarding of <strong> ${userName} </strong> account, do you want to continue?</p>`,
      'Yes',
      'Cancel'
    );

    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        this._usersService._loading.set(true);
        if(userId){
          this._usersComponent.expandPanelAfterRequest = userId;
          this._usersService.restartOnboarding(userName).subscribe({
            next: (resp: any) => {
              // snackbar shows success message from api response: resp.message
              this._portalDialogService.openUserPortalSnackBar(resp.message);
            },
            error: () => {
              this._portalDialogService.openUserPortalSnackBar(`Error restarting onboarding for the user selected`);
            },
            complete: () => {
              this._usersService._loading.set(false);
            },
          })
        }
      }
    })
  }

  openVerificationCodeDialog(userName: string, userId: string): void {
    const dialogRef = this._portalDialogService.userPortalDialog(
      'Send Verification Code',
      `<p> A verification code will be sent to <strong> ${userName} </strong> email, do you want to continue?</p>`,
      'Send',
      'Cancel'
    );

    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        this.expandPanel.emit({ state: true, expandPanelAfterRequest: userId });
        this._usersService._loading.set(true);
        this._usersService.sendVerificationCode(userName).subscribe({
          next: (response: any) => { 
            let verificationCode = response.data;
            this._usersService._loading.set(false);
            const _dialogRef = this._portalDialogService.userPortalDialog(
              `Verification code`,
              `<span>Please verify this code matches with the user's code.</span>
              <div class="flex justify-center">
              <h4>${verificationCode}</h4>
              </div>
              <h5>Do not refresh this page or close this dialog before verification is completed.</h4>`,
              'Complete',
              null
            );
            _dialogRef.afterClosed().subscribe((result) => {
              this._portalDialogService.openUserPortalSnackBar('Verification has finished.');
            });
          },
          error: (error) => {
            this._usersService._loading.set(false);
            console.log("Error: ", error);
            this._portalDialogService.openUserPortalSnackBar(`Error sending verification code.`);
          },
        });
      }
    })
  }

  /**
   * Validates the Auth Method of the user
   * @param userId 
   */
  validateTwoFactorMethod(userId: string): void {
    this._usersService.getTwoFactorMethod(userId, 'network-only')
      .then((result: TwoFactorMethod) => {
        if(result.data){
          this.enableEmailOtpButton = false;
        }else{
          this.enableEmailOtpButton = true;
        }
      })
      .catch((_error) => {
        console.log(_error);
      });
  }

  /**
   * Change the 2FA Method of a user from Auth App to Email OTP
   * @param userId 
   * @param userName 
   */
  enableEmailOtp(userId: string, userName: string): void {
    const dialogRef = this._portalDialogService.userPortalDialog(
      'Change Two Factor Authentication Method',
      `<p> Do you want to continue to disable the authenticator app and enable Email OTP for <strong> ${userName} </strong>?</p>`,
      'Yes',
      'Cancel'
    );

    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        if (userId) {
          this._usersComponent.expandPanelAfterRequest = userId;
          this._usersService.enableEmailOtpMethod(userId)
            .then((response: TwoFactorMethod) => {
              this._portalDialogService.openUserPortalSnackBar(response.message);
            })
            .catch((_error: string) => {
              console.log(_error);
              this._portalDialogService.openUserPortalSnackBar(`Error changing the 2FA method for this user`);
            });
        }
      }
    })
  }
}
