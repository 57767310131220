import { gql } from "apollo-angular";

export const BUILD_SEND_EMAIL_MUTATION = (conditionals: string) => {
  //Dynamic WHERE clauses based on conditionals
  const SEND_EMAIL_NOTIFICATION = `
    mutation sendNotificationEmail(
    $username:String!
    $receiverEmail: String!
    $supportConsoleEmailId: String!
    $year:String!
  ) {
    createSingleTemplateEmail(
      input: {
        toEmail: { email: $receiverEmail }
        templateID: $supportConsoleEmailId
        parameterKeys: [
          { key: "username", value: $username }
          { key: "year", value: $year }
          { key: "service", value: "LCP Tracker Support"}
          ${conditionals}
        ]
      }
    ) {
      data {
        status
        timeSent
        message
      }
      errors {
        message
      }
    }
  }`;

  return gql`${SEND_EMAIL_NOTIFICATION}`;
};

export const SEND_NOTIFICATION_EMAIL = gql`
mutation sendNotificationEmail(
  $username:String!
  $receiverName: String!
  $receiverEmail: String!
  $supportConsoleEmailId: String!
  $year:String!
) {
  createSingleTemplateEmail(
    input: {
      toEmail: { name: $receiverName, email: $receiverEmail }
      templateID: $supportConsoleEmailId
      parameterKeys: [
        { key: "username", value: $username }
        { key: "FN", value: "First Name" }
        { key: "LN", value: "Last Name" }
        { key: "Email", value: "Contact Email" }
        { key: "PN", value: "Phone Number" }
        { key: "year", value: $year }
        { key: "service", value: "LCP Tracker Support"}
      ]
    }
  ) {
    data {
      status
      timeSent
      message
    }
    errors {
      message
    }
  }
}
`;

export interface EmailInputData {
  username: string;
  receiverEmail: string;
  supportConsoleEmailId: string;
  year: string;
}

export interface ParameterEmailKey {
  key: string;
  value: string;
}

export interface EmailTokenResponse {
  access_token: string;
  expires_in: number;
  token_type: string;
  scope: string;
}

export interface EmailTokenRequestBody {
  grant_type: string;
  client_id: string;
  client_secret: string;
  scope: string;
}

export interface TemplateEmailData {
  status: number;
  timeSent: string;
  message: string;
}

export interface CreateSingleTemplateEmailResponse {
  createSingleTemplateEmail: {
    data: TemplateEmailData;
    errors: string | null;
  };
}

