<section class="support-layout" [ngClass]="{ 'support-layout-get-errors': hasErrorOcurred }">
  <!-- If there are no errors -->
  <ng-container *ngIf="!hasErrorOcurred">
    <!-- First column with information cards -->
    <div class="w-full flex-col gap-0 md:flex-row md:gap-2.28 flex mb-24">
      <div class="col-span-12 sm:max-w-fit lg:col-span-6 lg:float-left">
        <!-- Loop through each 'card' and display an 'app-info-card' component -->
        <ng-container *ngFor="let card of cards">
          <app-info-card [data]="card"></app-info-card>
          <div class="mb-2.28"></div>
        </ng-container>
      </div>

      <!-- Second column with support question cards -->
      <div class="col-span-12 sm:max-w-fit lg:col-span-6 lg:float-right">
        <!-- Loop through each 'question' and display an 'app-support-question-card' component -->
        <ng-container *ngFor="let question of questions; let last = last">
          <div class="mb-2.28" [ngClass]="{'mb-0': last}">
            <app-support-question-card [question]="question"></app-support-question-card>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <!-- If an error has occurred -->
  <ng-container *ngIf="hasErrorOcurred">
    <!-- Display an 'error-handle' component with a specific error category code -->
    <error-handle [errorCategoryCode]="ERROR_CATEGORY_CODE.Content_Retrieval_Error"></error-handle>
  </ng-container>
</section>
