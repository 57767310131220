import { inject, Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { throwError } from 'rxjs';
import { MessagingEmailService } from '../email-service/messaging-email.service';

@Injectable()
export class EmailGraphqlInterceptor implements HttpInterceptor {

    private messagingService = inject(MessagingEmailService)

    constructor() { }

    intercept(request: HttpRequest<any>, next: HttpHandler) {
        if (request.url.includes(environment.graphql.email)) {

            const emailAccessToken = this.messagingService.emailTokenValue;

            // If the Email token is missing, return an error response
            if (!emailAccessToken) {
                // Return a new HttpErrorResponse with the modified error message.
                return throwError(() => {
                    const httpResponse = {
                        error: 'Email Access Token is required but not present',
                        status: 400,
                        statusText: 'Bad Request',
                    }
                    return httpResponse;
                });
            }

            // Clone the original request and add the Authorization header with the Email token
            const modifiedRequest = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${emailAccessToken.access_token}`,
                },
            });

            // Pass the modified request to the next handler in the chain
            return next.handle(modifiedRequest);
        } else {
            // If the request is not to the Email endpoint, pass it through unmodified
            return next.handle(request);
        }
    }

}
