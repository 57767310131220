import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InfoCard } from 'src/app/shared/components/info-card/info-card';
import { SupportQuestion } from '../../components/support-question-card/support-question-card.interface';
import { SupportData } from '../../graphql/support-data.query';
import { ERROR_CATEGORY_CODE } from 'src/app/shared/components/error-handle/error-handle-const';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-page-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SupportComponent implements OnInit, OnDestroy {

  supportData!: SupportData;

  questions: SupportQuestion[] = [];

  cards: InfoCard[] = [];

  ERROR_CATEGORY_CODE = ERROR_CATEGORY_CODE;
  hasErrorOcurred: boolean = false;

  /*Handle Unsubcriptions*/
  private _unsubscribeAll: Subject<void> = new Subject<void>();

  constructor(private _route: ActivatedRoute) {}

  ngOnInit(): void {
    this._route.data.pipe(
      takeUntil(this._unsubscribeAll),
    ).subscribe({
      next: (data) => {
        if (data["supportData"].infoCardCollection && data["supportData"].trainingMaterialsCardCollection) {
          this.hasErrorOcurred = false;
          this.cards = data["supportData"].infoCardCollection.items;
          this.questions = data["supportData"].trainingMaterialsCardCollection.items;
        } else {
          this.hasErrorOcurred = true;
          this.cards = data["supportData"].data;
          this.questions = data["supportData"].data;
        }
      },
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.unsubscribe();
  }
}
