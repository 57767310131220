<div class="user-portal-navigation-wrapper">
  <!-- SECTION Navbar Component-->
  <div class="sticky">
    @if(!!(isMobileScreen$ | async) === false){
    <!-- SECTION Navbar Component Desktop-->
    <mat-toolbar class="desk-header-container" color="primary" sticky>
      <mat-toolbar-row>
        <img class="logo" alt="Lcptracker Logo" src="assets/images/logo.png" width="auto" height="auto" />
        <span class="spacer-end"></span>
        <div class="header-desk-right">
          <div class="header-desk-right">
            <!-- Notifications Button Container -->
            @if ((isServerError$ | async) === false) {
              <div *ngIf="(isServerError$ | async) === false"  class="header-notify">
                <!-- Notifications Button -->
                <button
                  class="bell-icon-button"
                  [matMenuTriggerFor]="menu"
                  aria-label="notification-button"
                  mat-mini-fab>

                  <!-- Notifications Button Icon -->
                  <mat-icon
                    class="text-white notification-bell"
                    matBadgeColor="accent"
                    matBadgeOverlap="true"
                    matBadgeSize="medium"
                    [matBadge]="countUnReadNotifications()"
                  >
                    notification_important
                  </mat-icon>
                </button>
                <mat-menu #menu="matMenu" xPosition="before">
                  <button mat-menu-item
                  (click)="openNotify(false)"
                  (keyup.enter)="openNotify(false)">
                    <mat-icon class="mark_email_unread" >mark_email_unread</mat-icon>
                    <span>Unread Notifications <span> ({{countUnReadNotifications()}})</span> </span>
                  </button>
                  <button mat-menu-item
                  (click)="openNotify(true)"
                  (keyup.enter)="openNotify(true)">
                    <mat-icon>mark_email_read</mat-icon>
                    <span>View All Notifications<span> ({{countAllNotifications()}})</span> </span>
                  </button>
                </mat-menu>
              </div>
            }
            <!-- Logout Button -->
            <app-button buttonType="header" label="Logout" prefixIcon="logout"
              (buttonClicked)="openLogoutConfirmationDialog()">
            </app-button>

          </div>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>
    }
     <!-- SECTION Navbar Component Mobile-->
      <mat-toolbar class="sub-header bg-sky-950">
        <mat-toolbar-row class="justify-end pr-10 h-14">
          @if((isMobileScreen$ | async)){
            <button class="btn-nav-toggle" type="button" aria-label="Toggle sidenav"
            mat-icon-button (click)="drawer.toggle()">
            <mat-icon class="text-white" aria-label="Side nav toggle icon">{{ drawer.opened ? 'close' : 'menu'}}
            </mat-icon>
          </button>
          }@else {
            <div class="user-avatar-name-container">
              <button color="primary" mat-button [matMenuTriggerFor]="menu">
                <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="account_circle"></mat-icon>{{userData?.userName}}</button>
                <mat-menu class="user-menu-card" #menu="matMenu" xPosition="before">
                <app-user-profile-card [user]="userData"></app-user-profile-card>
              </mat-menu>
            </div>
          }
        </mat-toolbar-row>
      </mat-toolbar>
      <!--SECTION Progress Navigation Bar-->
      @if (isNavigationAppOn()) { <mat-progress-bar mode="indeterminate"></mat-progress-bar>}
  </div>
  <!--SECTION Left Menu Component-->
  <div class="user-portal-drawer-container">
    <mat-drawer-container class="side-nav-container" [hasBackdrop]="(isMobileScreen$ | async)">
      <mat-drawer class="sidebar-drawer" #drawer [mode]="(isMobileScreen$ | async) ? 'over' : 'side'"
        [opened]="(isMobileScreen$ | async) === false" autoFocus="false" [ngSwitch]="selectedSubMenu">
        <div class="flex h-full">
          <!-- SECTION First Navigation Level -->
          <div class="flex-1 drawer-content">
            <div class="side-nav">
              <mat-nav-list class="h-full menu-drawer" *ngSwitchDefault>
                @if((isMobileScreen$ | async)){
                <!-- SECTION Mobile-->
                <div class="user-profile-for-mobile-container">
                  <div class="user-profile">
                    <!-- User Information -->
                    <div class="user-profile-information">
                      <div class="user-profile-information-detail">
                        <h5 class="text-name">{{userData?.firstName ? userData?.firstName: '' }} {{userData?.lastName ? userData?.lastName: ''}}</h5>
                      </div>
                    </div>
                    <div class="user-profile-content">
                      <span class="log-container" >
                        <span class="list-element-padding xl"><strong>Logged in as: </strong></span>
                        <span class="list-element-padding xl">{{userData?.userName}}</span>
                      </span>
                      <div class="user-profile-info">
                        <span class="mt-2 mb-2 list-element-padding">2FA is turned {{userData?.twoFactorEnabled ? 'ON' : 'OFF'}}</span>
                      </div>
                      <mat-nav-list class="menu-drawer-profile-action">
                        <a mat-list-item disableRipple [href]="editProfileUrl"  target="_blank" rel="noopener noreferrer">Edit profile</a>
                        <a id="menu-drawer-logout" mat-list-item disableRipple (click)="openLogoutConfirmationDialog()">Logout</a>
                      </mat-nav-list>
                      <mat-divider></mat-divider>
                    </div>
                  </div>
                  <ng-container *ngFor="let menu of menuItems">
                    <ng-container *ngIf="menu.target === 'user-portal'">
                      <a *ngIf="!notMobileAllowed.includes(menu.label)" mat-list-item [routerLink]="menu.route"
                        routerLinkActive="item-selected" (click)="drawer.toggle()">
                        <p>{{menu.label}}</p>
                      </a>
                    </ng-container>
                    <ng-container *ngIf="menu.target !== 'user-portal'">
                      <a *ngIf="!notMobileAllowed.includes(menu.label)" mat-list-item [attr.href]="menu.route"
                        target="_self" (click)="drawer.toggle()" (keyup.enter)="drawer.toggle()">
                        <p>{{menu.label}}</p>
                      </a>
                    </ng-container>
                  </ng-container>
                </div>
                }@else {
                <!-- SECTION Desktop-->
                <div class="side-nav-desktop-container">
                  <div class="desktop-menu" *ngIf="(isMobileScreen$ | async) === false">
                      <ng-container *ngFor="let menu of menuItems">
                        <!-- NOTE for links to an external url (fortify and other spas) -->
                        <ng-container *ngIf="!menu.node && menu.target !== 'user-portal'">
                          <a id="{{menu.label}}" class="item ripple" href="{{menu.route}}" target="_blank" rel="noopener noreferrer">
                            <span>
                              {{menu.route}}
                            </span>
                          </a>
                        </ng-container>
                        <!-- NOTE For inner app navigation -->
                        <ng-container *ngIf="!menu.node && menu.target === 'user-portal'">
                          <a class="drawer-option-menu"
                            [routerLinkActive]="isActiveRoute(menu.route) ? 'active-desktop-option' : ''" [routerLink]="menu.route"
                            (click)="resetSecondaryDrawer()" mat-list-item>
                            <ng-container *ngIf="menu.target === 'user-portal'">
                              <span id={{menu.label}}>
                                {{menu.label}}
                              </span>
                            </ng-container>
                          </a>
                        </ng-container>
                        <!-- NOTE For handling sub menu -->
                        <ng-container *ngIf="menu.label  && menu.node">
                          <a (click)="setCurrentNode(menu.node, menu.label , menu.node.label)" 
                            (keydown)="setCurrentNode(menu.node, menu.label, menu.node.label)" mat-list-item >
                            <span [ngClass]="{ 'parent-selected': selectedParentLabelNode === menu.label}">
                              {{menu.label}}
                            </span>
                            <mat-icon aria-hidden="false" aria-label="Chevron">chevron_right</mat-icon>
                          </a>
                        </ng-container>
                      </ng-container>
                  </div>
                  <!-- Co-browse button -->
                  <div class="co-browse-desktop">
                      <button mat-stroked-button class="monarch-button-co-browse monarch-button--header" tabindex="0"
                        onkeypress="window.coBrowsingInstance && coBrowsingInstance.showPopup();return false;"
                        onclick="window.coBrowsingInstance && coBrowsingInstance.showPopup();">
                        <mat-icon>
                          ads_click
                        </mat-icon> Co-Browse
                      </button>
                  </div>
                </div>
                }
              </mat-nav-list>
              <div class="user-profile" *ngSwitchCase="'mobile'">
                <!-- User Information -->
                <div class="user-profile-information">
                  <div class="user-profile-information-detail">
                    <!-- <h5 class="text-name">{{userData?.firstName ? userData?.firstName: '' }} {{userData?.lastName ? userData?.lastName: ''}}</h5> -->
                  </div>
                </div>
                <div class="user-profile-content">
                  <span class="list-element-padding xl">Logged in as:</span>
                  <div class="user-profile-info">
                    <!-- <span class="list-element-padding"><b>{{userData?.email}}</b></span> -->
                    <span class="list-element-padding">2FA is turned on</span>
                  </div>
                  <mat-nav-list class="menu-drawer-profile-action">
                    <a mat-list-item disableRipple routerLink="editprofileexample" (click)="drawer.toggle()">Edit
                      profile</a>
                    <!-- <a id="menu-drawer-logout" mat-list-item disableRipple (click)="openLogoutConfirmationDialog()">Logout</a> -->
                  </mat-nav-list>
                </div>
              </div>
            </div>
          </div>
          <!-- SECTION Second Navigation Level -->
          <ng-container *ngIf="isSecondDrawerOpen">
            <!-- NOTE Custom Dark background overlay with opacity -->
            <div
            *ngIf="(isMobileScreen$ | async) === false"
            class="second-navigation-overlay"
            (click)="closeSecondNavigation()"
            (keypress)="closeSecondNavigation()"></div>
             @if ((isMobileScreen$ | async) === false) {
              <div class="second-drawer-container w-72">
               @if (secondLevelNode && secondLevelNode.children) {
                  <div class="secondary-sidebar-animation"
                  [@fadeInLeft]="{ value: isSecondDrawerOpen, params: { timings: '300ms' } }"
                  [@fadeOutLeft]="{ value: isSecondDrawerOpen, params: { timings: '300ms' } }"
                  >
                    <h5 class="second-drawer-title">{{secondLevelNode.label}}</h5>
                    <div class="second-drawer-options-container" routerLinkActive="item-selected" (click)="sidebarStatusHandler()"
                      (keypress)="sidebarStatusHandler()" *ngFor="let secondTierNode of secondLevelNode.children">
                      <!-- NOTE Second level routes -->
                      <!-- NOTE For links to an external url -->
                      <ng-container *ngIf="!secondTierNode.node && secondTierNode.target !== 'user-portal'">
                        <div class="pl-2.28">
                          <a id={{secondTierNode.label}} class="no-underline item ripple" href="{{secondTierNode.route}}" target="_self" rel="noopener noreferrer">
                          <span class="sidebar-item second-drawer-option">
                            {{secondTierNode.label}}
                          </span>
                        </a>
                        </div>
                      </ng-container>
                      <!-- NOTE For inner app navigation -->
                      <ng-container *ngIf="!secondTierNode.node && secondTierNode.target === 'user-portal'">
                        <div class="pl-2.28">
                          <a class="no-underline" [routerLink]="secondTierNode.route" (click)="isSecondDrawerOpen = false">
                            <ng-container *ngIf="secondTierNode.target === 'user-portal'">
                              <span id={{secondTierNode.label}} class="sidebar-item second-drawer-option">
                                {{secondTierNode.label}}
                              </span>
                            </ng-container>
                          </a>
                        </div>
                      </ng-container>
                    </div>
                  </div>
               }
            </div>
             }
          </ng-container>
        </div>
      </mat-drawer>
      <!-- SECTION Drawer Content (Main Area) -->
      <mat-drawer-content>
        <div class="side-nav-content">
          <!-- SECTION Notification Component-->
          <app-notification></app-notification>
          <!-- SECTION Router Outlet-->
          <router-outlet></router-outlet>
        </div>
         <!-- SECTION Sticky Footer Component-->
        <div class="side-footer-content" [ngClass]="{'footer-lcp': (isMobileScreen$ | async) === false}">
          <app-footer></app-footer>
        </div>
      </mat-drawer-content>
    </mat-drawer-container>
  </div>
</div>