<!-- Footer Container -->
<footer class="footer-container">
  <div class="footer-links-policies">
    <!-- Copyright Section / Left Side -->
    <div class="footer-copyright sm:order-last md:order-first lg:order-first">
      <span>COPYRIGHT © {{currentYear}} LCPTRACKER INC, ALL RIGHTS RESERVED.</span>
    </div>

    <!-- LCP Tracker Redirection Pages / Center Side -->
    <div class="footer-lcp-pages">
      <span class="lcp-pages"><a href="https://lcptracker.com/contact/contact-sales" target="_blank" rel="noopener">Contact Sales</a></span>
      <span class="lcp-pages"><a href="https://lcptracker.com/contact/contact-support" target="_blank" rel="noopener">Contact Support</a></span>
      <span class="lcp-pages"><a href="https://lcptracker.com/about/company" target="_blank" rel="noopener">About</a></span>
      <span class="lcp-pages"><a href="https://lcptracker.com/education-webinar" target="_blank" rel="noopener">Webinar Sign Up</a></span>
      <!-- <mat-divider [vertical]="true" class="divider"></mat-divider> -->
      <span class="lcp-pages"><a href="https://lcptracker.com/demo-sign-up" target="_blank" rel="noopener">Demo Sign Up</a></span>
    </div>

    <!-- Policies Section / Right Side -->
    <div class="footer-container-policy">
      <span class="footer-policy"><a href="https://lcptracker.com/ccpa/" target="_blank" rel="noopener">CA Privacy Policy</a></span>
      <span class="footer-policy"><a href="https://lcptracker.com/privacy-policy-for-lcptracker" target="_blank" rel="noopener">Privacy Policy</a></span>
    </div>
  </div>
</footer>
