import { Component, Inject, OnInit, inject } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Subject, takeUntil } from "rxjs";
import { LicensesNodes } from "src/app/features/payment-cso/graphql/get-organization-data.query.graphql";
import { CsoPaymentService } from "src/app/features/payment-cso/services/cso-payment.service";

@Component({
  selector: "app-user-services-agreement-dialog",
  templateUrl: "./user-services-agreement-dialog.component.html",
  styleUrls: ["./user-services-agreement-dialog.component.scss"],
})
export class UserServicesAgreementDialogComponent implements OnInit {
  private _activateDialog = inject(MatDialogRef);
  private _csoInfo = inject(CsoPaymentService);
  private _unsubscribeAll = new Subject<boolean>();
  checkForm = new FormControl<boolean>(false, Validators.required);
  orgLicense!: LicensesNodes[];
  planLicense: string = "";
  orgLicenseDataByOrg: any;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      contractorOrgIdAndOrgName: any;
      orgLicenseWithoutAgreements: string[];
    }
  ) {}

  ngOnInit(): void {
    this._csoInfo.orgLicenses$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((value) => {
        this.orgLicense = value;
      });

    this.orgLicenseDataByOrg = this.filterOrgLicensesInfo(
      this.orgLicense,
      this.data
    );
  }

  filterOrgLicensesInfo(orgLicenseInfo: any, orgData: any): any {
    const matchedClaims: { orgName: any; value: any }[] = [];
    if(orgData && orgData.length){
      orgData.forEach((orgItem: any) => {
        const matchingClaims = orgLicenseInfo.filter(
          (claimItem: any) => claimItem.parentId === orgItem.orgId
        );
        matchingClaims.forEach((match: any) => {
          matchedClaims.push({
            orgName: orgItem.orgName,
            value: match.value,
          });
        });
      });
    }
    return matchedClaims;
  }

  onConfirm(value: boolean): void {
    this._activateDialog.close(value);
  }
}
