import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { OrgInfoSSO } from '../../pages/sso-integration/sso-integration-list/sso-integration-list.component';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { TPARule } from '../../pages/sso-integration/graphql/sso-enforcement.query';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-organization-provider-selection',
  templateUrl: './organization-provider-selection.component.html',
  styleUrls: ['./organization-provider-selection.component.scss'],
  standalone: true,
  imports: [
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule,
    FormsModule,
    MatInputModule,
    CommonModule
  ]
})
export class OrganizationProviderSelectionTPAComponent {


  orgData!: OrgInfoSSO[] | null;
  orgTpaInputValue = new FormControl('');
  tpaValue!: string;

  TpaRules: TPARule[] = [
    { name: 'NO SSO Enforcement', value: 'NO_ENFORCEMENT' },
    { name: 'Enforce on Admins', value: 'ADMINS_ONLY' },
    { name: 'Enforce on Admins and Contractors', value: 'EVERYONE' },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      tpaRule: string;
    },
    public activeDialog: MatDialogRef<OrganizationProviderSelectionTPAComponent>,
  ) {
    this.tpaValue = this.data.tpaRule === '' ? 'NO_ENFORCEMENT' : this.data.tpaRule;
    this.orgTpaInputValue.setValue(this.tpaValue);
  }

  /*istanbul ignore next*/
  onConfirm(): void {
    const tpaSelectionValue = this.orgTpaInputValue.value;
    this.activeDialog.close(tpaSelectionValue);
  }
}
