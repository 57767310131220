import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { WatchQueryFetchPolicy } from '@apollo/client/core';
import { BehaviorSubject, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GraphqlHostService {
  loading = true;
  queryResultData: any;
  context: any = null;
  onQueryLoadedChange: BehaviorSubject<any> = new BehaviorSubject<{}>({});

  constructor(private apolloProvider: Apollo) {}

  /**
   * @param serverName
   * @param graphQuery
   * @param vars
   * @param optionalContext
   * @param policy
   * @returns The graphql query data pulled from the GraphQL API.
   */
  getQueryResults(
    serverName: string,
    graphQuery: any,
    vars?: any,
    optionalContext?: any,
    policy?: WatchQueryFetchPolicy,
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      /*istanbul ignore if*/
      if (optionalContext) {
        this.context = optionalContext.context;
      }
      this.apolloProvider
        .use(serverName)
        .watchQuery<any>({
          fetchPolicy: policy,
          query: graphQuery,
          variables: vars,
          context: this.context, 
        })
        .valueChanges.subscribe({
          next: (response) => {
            resolve(response.data);
          },
          error: (err: any) => {
            /*istanbul ignore if*/
            if (err?.message.includes('Missing Authorization header')) {
              console.log(err);
            }
            reject(err);
          },
          complete: () => {
            if(environment.env !== 'prod'){
              console.log('complete');
            }
          },
        });
    });
  }

  /**
   * @param serverName any
   * @param graphQuery any
   * @param vars any
   */
  getMutationResults(
    serverName: string,
    graphQuery: any,
    vars: any,
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apolloProvider
        .use(serverName)
        .mutate<any>({
          mutation: graphQuery,
          variables: vars,
        })
        .subscribe({
          next: (response: any) => {
            this.setReturningData(response);
            resolve(response.data);
          },
          error: (err: any) => {
            const withErrors = {
              error:
                err.message + ', dest: ' + graphQuery.definitions[0].name.value,
            };
            /* istanbul ignore next */
            throwError(() => new Error(err));
            reject(withErrors);
          },
        });
    });
  }

  setReturningData(response: any): void {
    this.queryResultData = response.data;
    this.onQueryLoadedChange.next(this.queryResultData);
  }
}
