import { inject, Injectable, signal } from '@angular/core';
import { map } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { FetchPolicy } from '@apollo/client/core';
import { GraphqlHostService } from 'src/app/shared/services/graphql-host/graphql-host.service';
import {
  PaginationState,
  PaginationVars,
  SupportConsoleQueryVars,
  UserData,
  UsersRequestResponse,
  NEW_SUPPORT_CONSOLE_QUERY,
} from '../graphql/support-console.queries.graphql';
import {
  BoolResultModel,
  RESET_PASSWORD_USER_BY_USERNAME,
  ResetPasswordReturningEntity,
} from '../graphql/mutations/reset-password-user-by-username.query.graphql';
import {
  UNLOCK_USER_BY_USERNAME,
  UnlockUserReturningEntity,
} from '../graphql/mutations/unlock-user-by-username.query.graphql';
import { RECOVERY_CODE_USER_BY_ID, RecoveryCodeReturningEntity, StringResultModel } from '../graphql/mutations/recovery-code-user-by-id.query.graphql';
import { HttpClient } from '@angular/common/http';
import { INIT_PAGINATION_STATE, INIT_PAGINATION_VARS } from '../consts/consts';
import { ENABLE_TWO_FACTOR_EMAIL_OTP, EnableEmailOtpResponse, GET_TWO_FACTOR_METHOD, TwoFactorMethod, TwoFactorMethodResponse } from '../graphql/two-factor-method.queries.graphql';
import { UPDATE_USER_DATA, UpdateUserData, UpdateUserDataModel, UpdateUserDataResponse } from '../graphql/mutations/edit-user-data.query.graphql';
import { CreateChangeLog, SUPPORT_CONSOLE_SAVE_CHANGE_LOG } from '../graphql/change-log.queties.graphql';
import { EMPTY_SEARCH_VALUE_FOR_CUSTOM_USER_QUERY } from 'src/app/core/consts/core-userportal-const';

export const PAGE_CONTACTS_SIZE_INIT = 10;
@Injectable({
  providedIn: 'root',
})
export class UsersService {

  private readonly _graphqlHostService = inject(GraphqlHostService);
  private readonly httpClient = inject(HttpClient);

  //New Signals to replace the BehaviorSubjects
  readonly _paginationVars = signal<PaginationVars>(INIT_PAGINATION_VARS);
  readonly _paginationState = signal<PaginationState | null>(INIT_PAGINATION_STATE);
  readonly _lastPageSizeChange = signal<number>(0);
  readonly _onSearchText = signal<string>(EMPTY_SEARCH_VALUE_FOR_CUSTOM_USER_QUERY);
  readonly _onfilterByCriteria = signal<string | null>('userName');
  readonly _loading = signal<boolean>(false);
  readonly _isSaving = signal<boolean>(false);
  readonly _userData = signal<UserData | null>(null);

  constructor() { }

  /**
 * Searches and filters the data based on the provided criteria.
 * @param searchCriteria The search criteria to filter the data.
 * @param filter The optional filter criteria to further refine the search.
 */
  searchAndFilterBy(searchCriteria: string, filter: string | null): void {
    this._onSearchText.set(searchCriteria);
    this._onfilterByCriteria.set(filter);
  }


  getDataBasedOnVars(): SupportConsoleQueryVars {
    return {
      searchCriteria: this._onSearchText(),
      searchField: this._onfilterByCriteria(),
      first: this._paginationVars().first,
      after: this._paginationVars().after,
      last: this._paginationVars().last,
      before: this._paginationVars().before,
    };
  }


  updatePageState(requestResponse: UsersRequestResponse): PaginationState {
    let pageState: PaginationState;

    if (requestResponse.users.pageInfo.hasNextPage) {
      // If there is a next page, set the pageState accordingly
      pageState = {
        endCursor: requestResponse.users.pageInfo.endCursor,
        startCursor: requestResponse.users.pageInfo.startCursor,
        totalElements: requestResponse.users.totalCount,
        pageSize: this._paginationVars().first ? this._paginationVars().first : this._paginationVars().last,
      };
    } else {
      // If it's the last page, determine the pageSize based on the totalCount
      const pageSizeOnLastPage: number | null = this._paginationVars().first ? this._paginationVars().first : this._paginationVars().last;
      // If the totalCount is less than or equal to the pageSizeOnLastPage, use pageSizeOnLastPage
      // Otherwise, use the lastPageSizeChange value (most recently selected pageSize)
      pageState = {
        endCursor: null,
        startCursor: requestResponse.users.pageInfo.startCursor,
        totalElements: requestResponse.users.totalCount,
        pageSize: requestResponse.users.totalCount <= pageSizeOnLastPage! ? pageSizeOnLastPage : this._lastPageSizeChange(),
      };
    }

    return pageState;
  }

  /**
   * Makes the HTTP request to fetch the list of users
   * @returns The list of paginated users
   */
  getUsers(policy: FetchPolicy = 'network-only'): Promise<UserData> {
    this._loading.set(true);
    const VARS: SupportConsoleQueryVars = this.getDataBasedOnVars();
    const QUERY = NEW_SUPPORT_CONSOLE_QUERY;
    const optionalContext = null;

    return new Promise((resolve, reject) => {
      this._graphqlHostService
        .getQueryResults(
          environment.graphqlServerName.rbs,
          QUERY,
          VARS,
          optionalContext,
          policy
        )
        .then((response: UsersRequestResponse) => {
          this._paginationState.set(this.updatePageState(response));
          this._loading.set(false);
          this._userData.set(response.users);
          resolve(response.users);
        })
        .catch((error) => {
          this._userData.set(null);
          this._loading.set(false);
          reject(error);
        });
    });
  }



  /**
   * Unlock user by username using graphql mutations
   * @param userName
   */
  unlockUser(userName: string): Promise<BoolResultModel> {
    this._loading.set(true);
    const vars = {
      userName,
    };
    return new Promise((resolve, reject) => {
      this._graphqlHostService
        .getMutationResults(
          environment.graphqlServerName.rbs,
          UNLOCK_USER_BY_USERNAME,
          vars,
        )
        .then((response: UnlockUserReturningEntity) => {
          this._loading.set(false);
          resolve(response.unlockUser);
        })
        .catch((error: string) => {
          this._loading.set(false);
          const gError = {
            origin: 'unlockUser',
            error,
          };
          reject(gError);
        });
    });
  }

  /**
   * Get recovery code user by user ID using graphql mutations
   * @param userId
   */
  getRecoveryCode(userId: string): Promise<StringResultModel> {
    this._loading.set(true);
    const vars = {
      userId,
    };
    return new Promise((resolve, reject) => {
      this._graphqlHostService
        .getMutationResults(
          environment.graphqlServerName.rbs,
          RECOVERY_CODE_USER_BY_ID,
          vars
        )
        .then((response: RecoveryCodeReturningEntity) => {
          this._loading.set(false);
          resolve(response.recoveryCode);
        })
        .catch((error: string) => {
          this._loading.set(false);
          const gError = {
            origin: 'getRecoveryCode',
            error,
          };
          reject(gError);
        });
    });
  }

  /**
   * Graphql Mutation to update user information
   * @param user Object with user data
   * @returns Promise<UpdateUserDataModel>
   */
  updateUserData(user: UpdateUserData): Promise<UpdateUserDataModel> {
    this._loading.set(true);
    const vars: UpdateUserData = {
      userName: user.userName,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      phoneNumber: user.phoneNumber
    };

    return new Promise((resolve, reject) => {
      this._graphqlHostService
        .getMutationResults(
          environment.graphqlServerName.rbs,
          UPDATE_USER_DATA,
          vars
        )
        .then((response: UpdateUserDataResponse) => {
          //this._loading.set(false);
          resolve(response.updateUserData);
        })
        .catch((error: string) => {
          this._loading.set(false);
          const gError = {
            origin: 'updateUserData',
            error,
          };
          reject(gError);
        });
    });
  }

  /**
   * Service call to fortify api to post a change log from a user update
   * @param changeLog
   * @returns http response
   */
  saveChangeLog(changeLog: CreateChangeLog): Promise<CreateChangeLog> {
    return new Promise((resolve, reject) => {
      this._graphqlHostService
        .getMutationResults(
          environment.graphqlServerName.rbs,
          SUPPORT_CONSOLE_SAVE_CHANGE_LOG,
          changeLog
        )
        .then((response: CreateChangeLog) => {
          resolve(response);
        })
        .catch((error: string) => {
          this._loading.set(false);
          const gError = {
            origin: 'updateUserData',
            error,
          };
          reject(gError);
        });
    });
  }

  /**
   * Service call to delete sessions from a user
   * @param userId 
   * @returns http response
   */
  deleteActiveSessionsByUserId(userId: string) {
    const url = environment.applications.fortify.api + `/support/usermanager/V02/json/deletesessions/user/${userId}`;
    return this.httpClient.post(url, {}).pipe(
      map(resp => resp)
    )
  }
  /**
 * Reset password for user by username using graphql mutations
 * @param userName
 */
  resetPassword(userName: string): Promise<BoolResultModel> {
    this._loading.set(true);
    const vars = {
      userName,
    };
    return new Promise((resolve, reject) => {
      this._graphqlHostService
        .getMutationResults(
          environment.graphqlServerName.rbs,
          RESET_PASSWORD_USER_BY_USERNAME,
          vars,
        )
        .then((response: ResetPasswordReturningEntity) => {
          this._loading.set(false);
          resolve(response.resetPassword);
        })
        .catch((error: string) => {
          this._loading.set(false);
          const gError = {
            origin: 'resetPassword',
            error,
          };
          reject(gError);
        });
    });
  }

  /**
   * Service call to send a verification code to a user
   * @param userId 
   * @returns http response 
   */
  sendVerificationCode(username: string) {
    const formData = new FormData();
    formData.append('username', username);
    const url = environment.applications.fortify.api + `/support/usermanager/V02/json/verificationcode/user`;
    return this.httpClient.post(url, formData).pipe(
      map(resp => resp)
    )
  }

  /**
   * Service call to reset Onboarding claims for a user
   * @param userName 
   * @returns http response
   */
  restartOnboarding(userName: string) {
    const formData = new FormData();
    formData.append('username', userName);
    const url = environment.applications.fortify.api + `/support/usermanager/V02/json/reonboardUser/user`;
    return this.httpClient.post(url, formData).pipe(
      map(resp => resp)
    )
  }

  /**
   * Graphql query to retrieve 2FA method of a user
   * @param userId 
   * @returns TwoFactorMethod response
   */
  getTwoFactorMethod(userId: string, policy: FetchPolicy = 'network-only'): Promise<TwoFactorMethod> {
    const VARS = {
      userId
    };
    const QUERY = GET_TWO_FACTOR_METHOD;
    const optionalContext = null;

    return new Promise((resolve, reject) => {
      this._graphqlHostService
        .getQueryResults(
          environment.graphqlServerName.rbs,
          QUERY,
          VARS,
          optionalContext,
          policy
        )
        .then((response: TwoFactorMethodResponse) => {
          resolve(response.isTwoFactorEmailCodeEnabled);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Graphql mutation to change the 2FA Auth App to Email Otp
   * @param userId 
   * @returns TwoFactorMethod response
   */
  enableEmailOtpMethod(userId: string): Promise<TwoFactorMethod> {
    this._loading.set(true);
    const vars = {
      userId,
    };
    return new Promise((resolve, reject) => {
      this._graphqlHostService
        .getMutationResults(
          environment.graphqlServerName.rbs,
          ENABLE_TWO_FACTOR_EMAIL_OTP,
          vars
        )
        .then((response: EnableEmailOtpResponse) => {
          this._loading.set(false);
          resolve(response.enableTwoFactorEmailCode);
        })
        .catch((error: string) => {
          this._loading.set(false);
          const gError = {
            origin: 'enableEmailOtpMethod',
            error,
          };
          reject(gError);
        });
    });
  }
}
