import { Component, Input } from '@angular/core';
import { Header, Links } from './footer-interface';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent{

  @Input() footerData: any = null;
  @Input() hasContentfulError: boolean = false;
  headers!:Header[] | null;
  links!:Links[] | null;
  _unsubscribeAll: Subject<boolean> = new Subject();

  /**
   * Separates Headers and Links in different arrays to display them on screen
   */
  getFooterData():void{
    // Gets all headers in a single array
    const headers = this.footerData.filter(
      ((element: { type: string; }) => element.type === 'Header')
    );

    // Sorts the array of headers
    const sortedHeaders = headers.sort((element1: { categoryIndex: number; },element2: { categoryIndex: number; }) => element1.categoryIndex - element2.categoryIndex);

    // Stores the array of headers in the array to be displayed on screen
    this.headers = sortedHeaders;

    // Gets all links in a single array
    this.links = this.footerData.filter(
      (element: { type: string; }) => element.type === 'Link'
    );
  }

  /**
   * Get the current year and show it in the
   * template
   */
  get currentYear(): string {
    return JSON.stringify(new Date().getFullYear());
  }
}
