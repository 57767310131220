import { Component } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-custom-snackbar',
  template: `
    <div class="custom-snackbar">
      <div [innerHTML]="message"></div>
      <button (click)="closeCustomSnackBar()" *ngIf="action" mat-button>{{ action }}</button>
    </div>
`,
  styleUrls: ['./custom-snackbar.component.scss']
})
export class CustomSnackbarComponent{

  constructor(
    public activeSnackBar: MatSnackBarRef<CustomSnackbarComponent>
  ) { }

  message!: string;
  action!: string;
  showSnackbar: boolean = true;


  closeCustomSnackBar() {
    this.activeSnackBar.dismiss();
  }
}
