import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Subject, Subscription } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { AuthenticationService } from "src/app/features/authentication/services/authentication.service";
import { Org } from "src/app/features/dashboard/graphql/profile-info.query";
import { DataClaim } from "src/app/features/payment-cso/graphql/mutations/add-agreement-claim-to-org.query.graphql";
import { Agreement } from "src/app/features/payment-cso/graphql/verify-lic-agreement-user-in-org.query.graphql";
import { CsoPaymentService } from "src/app/features/payment-cso/services/cso-payment.service";
import { getTargetUrl, formatDateTimeToLocaleString } from "src/app/shared/helpers";
import { UserPortalDialogService } from "src/app/shared/services/dialog/user-portal-dialog.service";
import { GetUserDataByIDService } from "../services/get-user-data-by-id.service";
import { UserServicesAgreementDialogComponent } from "./user-services-agreement-dialog/user-services-agreement-dialog.component";
import { BasicContractorAndORgInformation } from "./interfaces/user-services-agreements";
@Component({
  selector: "app-user-services-agreement",
  template: ``,
  styles: [],
})
export class UserServicesAgreementComponent implements OnInit, OnDestroy {
  constructor(
    private authenticationService: AuthenticationService,
    private csoInfo: CsoPaymentService,
    private userPortalDialogService: UserPortalDialogService,
    private userService: GetUserDataByIDService,
    private dialog: MatDialog
  ) { }

  organizations: Org[] | Org = [];
  contractors: Org[] = [];
  licenseValue = false;
  userId!: string;
  contractorsOrgId: string[] = [];
  userInOrg: string[] = [];
  orgsWithLicense?: string[] = [];
  userInOrgIdsData: string[] = [];
  formattedDate: string = "";
  userAgreementsData: string[] = [];
  purchaseValue: string = '';
  contractorOrgIdAndOrgName: BasicContractorAndORgInformation[] = [];

  private unsubscribeAll = new Subject<void>();
  private purchaseDataSubscription?: Subscription;

  ngOnInit(): void {
    if (getTargetUrl() === "/cso/verify") {
      this.getUserId();
      this.getAllOrgsFromUser();
      this.verifyLicensesInContractors();
      this.setPurchaserAgreements();
      this.verifyAgreementsByUserAndOrgIds();
    }
  }

  setPurchaserAgreements(): void {
    if (!this.csoInfo.getPurchaseDataSubject) {
      return;
    }
    this.csoInfo.getPurchaseDataSubject
      .pipe(takeUntil(this.unsubscribeAll), take(1))
      .subscribe({
        next: (response) => {
          if (response?.createClaim?.data?.parentId) {
            this.purchaseValue = response.createClaim.data.parentId;

            const orgArray = this.contractors.find((item) => {
              return item.org.id === this.purchaseValue;
            });

            this.addAgreementForUser(orgArray?.userInOrgId!);
          }
        },
        error: (error) => {
          console.error(error);
        }
      })
  }

  async verifyLicensesInContractors(): Promise<void> {
    if (Array.isArray(this.organizations)) {
      const onlyContractors = this.organizations.filter((orgs: Org) => orgs.org.orgType === "CONTRACTOR");
      this.contractors = [...onlyContractors];
    }

    this.contractorOrgIdAndOrgName = this.contractors.map((item) => {
      return {
        orgId: item.org.id,
        orgName: item.org.orgName,
        userInOrg: item.userInOrgId,
      };
    });

    this.contractorsOrgId = this.contractors.map((orgElements: Org) => orgElements.org.id);

    try {
      const LicenseResponse = await this.csoInfo.getTypeOfLicensesByOrgIds(this.contractorsOrgId);

      this.orgsWithLicense = LicenseResponse.map(
      /* istanbul ignore next */
        (license) => license.parentId
      );
/* istanbul ignore next */
      this.contractorOrgIdAndOrgName = this.contractorOrgIdAndOrgName.filter((item: BasicContractorAndORgInformation) => this.orgsWithLicense?.includes(item.orgId));
/* istanbul ignore next */
      if (LicenseResponse.length) {
        this.licenseValue = true;
      }
      /* istanbul ignore next */
      return this.verifyAgreementsByUserAndOrgIds();
    } catch (error) {
    /* istanbul ignore next */
      console.error("An error has occurred: ", error);
    }
  }

/* istanbul ignore next */
  async verifyAgreementsByUserAndOrgIds(): Promise<void> {
    if (!this.licenseValue) {
      return;
    }

    const resultArray = this.contractors.filter((item) => {
      return this.orgsWithLicense?.some((data) => item.org.id === data);
    })

    this.userInOrgIdsData = this.contractors.map((user) => user.userInOrgId);
    const agreementsAcceptedByUserInOrg = await this.csoInfo.getUserServicesAgreement(this.userInOrgIdsData);

    if (!agreementsAcceptedByUserInOrg.length) {
      this.userInOrgIdsData = resultArray.map((value) => value.userInOrgId);
      this.openAgreementDialog();
      return;
    }

    this.userInOrgIdsData = resultArray.map((value) => value.userInOrgId);
    this.userAgreementsData = this.compareLicenseWithAgreements(
      this.userInOrgIdsData,
      agreementsAcceptedByUserInOrg
    );

    if (!this.userAgreementsData[0]) {
      return;
    }
    this.userInOrgIdsData = this.userAgreementsData;
    this.openAgreementDialog();
  }

  async acceptTermsAndConditions(): Promise<void> {
    const userInOrgSelected = this.userInOrgIdsData;
    let successCount = 0;
    if (userInOrgSelected.length > 0) {
      for (const userInOrg of userInOrgSelected) {
        try {
          await this.addAgreementForUser(userInOrg);
          successCount++;
        } catch (error) {
          console.error(error);
        }
      }
    } else {
      console.log('No users selected.');
    }
  }
/* istanbul ignore next */
  private async addAgreementForUser(userInOrgId: string): Promise<void> {

    const addAgreementParameters: DataClaim = {
      parentId: userInOrgId,
      key: "CSO_AcceptedUserLic",
      value: "true - User has accepted the new agreement.",
      isPublic: false,
      dateStart: formatDateTimeToLocaleString(-1440),
      dateExpire: formatDateTimeToLocaleString(5256000)
    };

    try {
      await this.csoInfo.addUserAgreements(addAgreementParameters);
    } catch (error) {
      console.error(error);
    }
  }
/* istanbul ignore next */
  openAgreementDialog(): void {
    let userAgreementsInfo:BasicContractorAndORgInformation[] = [];
    if (!this.userAgreementsData.length && this.contractorOrgIdAndOrgName.length) {
      userAgreementsInfo = this.contractorOrgIdAndOrgName;

    } else {
      userAgreementsInfo = this.contractorOrgIdAndOrgName.filter(
        (item: any) => {
          return this.userAgreementsData.some(
            (agreement) => agreement === item.userInOrg
          );
        }
      );
    }

    const agreementConfirmationDialogRef = this.dialog.open(
      UserServicesAgreementDialogComponent,
      { disableClose: true, data: userAgreementsInfo }
    );

    agreementConfirmationDialogRef.afterClosed().subscribe((value) => {
      if (!value) {
        this.showDeclineConfirmationDialog();
      } else {
        this.acceptTermsAndConditions();
      }
    });
  }

  showDeclineConfirmationDialog(): void {
    const dialogRef = this.userPortalDialogService.userPortalDialog(
      'Decline User Services Agreement',
      `<p>
      By declining the User Services Agreement, you will be logged out. 
      <br/> <br/> Are you sure you want to log out?
      </p>`,
      'Log Out',
      'Back',
    );
    dialogRef.afterClosed().subscribe({
      next: response => {
        if (response) {
          this.showLoadingLogoutDialog();
          this.logout();
        } else {
          this.openAgreementDialog();
        }
      }
    })
  }

  showLoadingLogoutDialog(): void {
    this.userPortalDialogService.userPortalDialog(
      'Logging Out',
      `<p>
        Closing Session...
      </p>`,
      null,
      null,
      '430px',
      true
    );
  }
/* istanbul ignore next */
  ngOnDestroy(): void {
    if (this.purchaseDataSubscription) {
      this.purchaseDataSubscription.unsubscribe();
    }
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
/* istanbul ignore next */
  getAllOrgsFromUser(): void {
    this.userService.getContractors
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (response) => {
          this.organizations = response;
        },
        error: (error) => {
          console.error(error);
        },
      });
  }

  // helper methods
/* istanbul ignore next */
  logout(): void {
    this.authenticationService.logout();
  }

  getUserId(): void {
    this.userId = this.authenticationService.userId.value;
  }

  compareLicenseWithAgreements(idsUserInOrgLicenses: string[], agreements: Agreement[]): string[] {
    const missingAgreementIds: string[] = [];
    idsUserInOrgLicenses.forEach((userInOrgId) => {
      const hasAgreement = agreements.some((agreement) => agreement.parentId === userInOrgId );
      if (!hasAgreement) {
        missingAgreementIds.push(userInOrgId);
      }
    });
    return missingAgreementIds;
  }
}