import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, catchError, forkJoin, map, of, switchMap, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CsoPlans } from './azure.interfaces';
import { AzureApiResponse, EmailClientCredentials } from '../interfaces/global-interfaces';
import { AuthenticationService } from 'src/app/features/authentication/services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AzureService {
  private _http = inject(HttpClient);
  private _authenticationService = inject(AuthenticationService);
  private url = environment.azureServices;

  /*Subjects*/
  contentSubject = new BehaviorSubject<string>('');
  content$ = this.contentSubject.asObservable();

  csoPlansSubject = new BehaviorSubject<CsoPlans>({ free: '', monthly: '', annual: '' });
  csoPlans$ = this.csoPlansSubject.asObservable();

  spaceSubject = new BehaviorSubject<string>('');
  space$ = this.spaceSubject.asObservable();

  /*Subjects to handle email client credentials*/
  supportConsoleEmailSubject = new BehaviorSubject<EmailClientCredentials | null>(null);
  supportConsoleEmail$ = this.supportConsoleEmailSubject.asObservable();

  constructor() { }

  getAzureApiLandingInfo() {
    const csoInfo = { free: '', monthly: '', annual: '' };
    const contentful$ = this._http.post<AzureApiResponse>(`${this.url}contentful`, {});
    const contentSpace$ = this._http.post<AzureApiResponse>(`${this.url}spaceId`, {});
    const freePlan$ = this._http.post<AzureApiResponse>(`${this.url}csoDevFreePlan`, {});
    const monthlyPlan$ = this._http.post<AzureApiResponse>(`${this.url}csoDevMonthlyPlan`, {});
    const annualPlan$ = this._http.post<AzureApiResponse>(`${this.url}csoDevAnnualPlan`, {});

    return forkJoin([contentful$, freePlan$, monthlyPlan$, annualPlan$, contentSpace$]).pipe(
      switchMap(([contentfulData, freePlanData, monthlyPlanData, annualPlanData, spaceData]) => {
        csoInfo.free = freePlanData.data;
        csoInfo.monthly = monthlyPlanData.data;
        csoInfo.annual = annualPlanData.data;

        this.csoPlansSubject.next(csoInfo);
        this.contentSubject.next(contentfulData.data);
        this.spaceSubject.next(spaceData.data);

        return of(true);
      }),
      catchError(error => {
        console.error('Error in getAzureApiLandingInfo', error);
        return of(false);
      })
    );
  }



  getAzureClientEmailCredentials() {
    const token = this._authenticationService.fortifyToken.value;
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    const clientEmailName$ = this._http.post<AzureApiResponse>(`${this.url}clientEmailId`, {}, { headers });
    const clientEmailSecret$ = this._http.post<AzureApiResponse>(`${this.url}clientEmailSecret`, {}, { headers });
    const emailTemplate$ = this._http.post<AzureApiResponse>(`${this.url}emailTemplateId`, {}, { headers });

    return forkJoin([clientEmailName$, clientEmailSecret$, emailTemplate$]).pipe(
      map(([clientEmailName, clientEmailSecret, emailTemplate]) => {
        const emailCredentials: EmailClientCredentials = {
          clientEmailName: clientEmailName.data,
          clientEmailSecret: clientEmailSecret.data,
          emailTemplate: emailTemplate.data
        };

        this.supportConsoleEmailSubject.next(emailCredentials);

        return true;
      }),
      catchError(error => {
        console.error('Error in getAzureClientEmailCredentials', error);
        return of(false);
      })
    );
  }

  get emailCredentialsValue(): EmailClientCredentials | null {
    return this.supportConsoleEmailSubject.value || null;
  }

}
