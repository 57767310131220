import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthOptions, EventTypes, LoginResponse, OidcClientNotification, OidcSecurityService, OpenIdConfiguration, PublicEventsService} from 'angular-auth-oidc-client';
import {
  distinctUntilChanged,
  filter,
  Observable,
  map,
  BehaviorSubject,
  concatMap,
  of,
  forkJoin,
  catchError,
} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Certified, LcpTracker } from '../interfaces/auth-interface';
import { UserPortalAuthData } from '../interfaces/local-storage-auth-data.interface';
import { USER_PORTAL_AUTH_DATA_EMPTY, USER_PORTAL_AUTH_DATA_ITEM } from '../consts/consts';
import { ErrorHandleService } from 'src/app/shared/services/error-handle/error-handle.service';
import { cleanLocalStorage, cleanSessionStorage } from 'src/app/shared/helpers';
import { UserProfileData } from '../../dashboard/graphql/profile-info.query';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {

  isAuthenticated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  userId: BehaviorSubject<string> = new BehaviorSubject<string>('');
  userName: BehaviorSubject<string> = new BehaviorSubject<string>('');
  fortifyToken: BehaviorSubject<string> = new BehaviorSubject<string>('')
  fortifyRoles: BehaviorSubject<string | string[]> = new BehaviorSubject<string | string[]>([]);
  rbsRoles: BehaviorSubject<string | string[]> = new BehaviorSubject<string | string[]>([]);

  canUseSupportToolsSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  configuration!: OpenIdConfiguration[];
  userPortalAuthData: UserPortalAuthData = USER_PORTAL_AUTH_DATA_EMPTY;

  private readonly urlTrackerApi = environment.LcpTrackerApi;
  private readonly urlCertifiedApi = environment.CertifiedApi;

  constructor(
    private http: HttpClient,
    private oidcSecurityService: OidcSecurityService,
    private eventService: PublicEventsService,
    private router: Router,
    private errorHandleService: ErrorHandleService
  ) { }

  evaluateUserRoles(expectedRole: string): boolean {
    if (this.rbsRoles.value.includes(expectedRole)) {
      return true;
    } else {
      return false;
    }
  }

  evaluateITRoles(): { canRead: boolean; canWrite: boolean } {
    const roles = this.rbsRoles.value;
    const isTester = roles.includes('IT Security Tester');
    const isManager = roles.includes('IT Security Manager');

    if (isManager) {
      return { canRead: true, canWrite: true };
    } else if (isTester) {
      return { canRead: true, canWrite: false };
    } else {
      return { canRead: false, canWrite: false };
    }
  }


  IsAuthenticatedAlert(): void {
    this.oidcSecurityService.isAuthenticated$.subscribe((response) => {
      if (response.isAuthenticated === true) {
        if (environment.env !== 'prod') {
          console.log(
            `%cIS AUTH`,
            'background-color: green ; color: white ; font-weight: bold ; padding: 8px ;',
          );
        }
        this.isAuthenticated$.next(true);
      } else {
        if (environment.env !== 'prod') {
          console.log(
            `%cIS NOT AUTH`,
            'background-color: red ; color: white ; font-weight: bold ; padding: 8px ;',
          );
        }
        this.isAuthenticated$.next(false);
      }
    });
  }

  /**
   * checkSessionEvent
   */
  sessionStateFromIDPListener(): void {
    this.eventService
      .registerForEvents()
      .pipe(
        filter(
          notification =>
            notification.type === EventTypes.CheckSessionReceived,
        ),
        distinctUntilChanged((prev, curr) => prev.value === curr.value),
      )
      .subscribe((notification: OidcClientNotification<string>) => {
        const currentDate = new Date();
        if (environment.env !== 'prod') {
          console.log(
            'CheckSessionReceived with value from app',
            notification,
            `${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`,
          );
        }
        if (notification.value == 'changed') {
          if (environment.env !== 'prod') {
            console.log(
              `%cShould logout`,
              'background-color: red ; color: white ; font-weight: bold ; padding: 8px ;',
            );
          }
          this.logout();
          this.router.navigateByUrl('/');
        }
      });
  }


  refreshTokenEventListener(): void {
    this.eventService
      .registerForEvents()
      .pipe(
        filter(
          notification => notification.type === EventTypes.SilentRenewStarted,
        ),
      )
      .subscribe((notification: OidcClientNotification<string>) => {
        const currentDate = new Date();
        if (environment.env !== 'prod') {
          console.warn(
            'Refresh Token with value from app',
            notification,
            `${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`,
          );
        }
         /* istanbul ignore if */
        if (notification.value === 'changed') {
          if (environment.env !== 'prod') {
            console.log(
              `%cNEW ACCESS TOKEN`,
              'background-color: blue ; color: white ; font-weight: bold ; padding: 8px ;',
            );
          }
        }
      });
  }

  /**
   * Creates the an empty instance of the authentication data object and
   * stores it in the local storage.
   */
  createAuthDataObjectInLocalStorage(): void {
    // Gets the data stored in the local storage
    let userPortalAuthenticationData: UserPortalAuthData | string | null =
      localStorage.getItem(USER_PORTAL_AUTH_DATA_ITEM);
    // The object gets created with null value in each object attribute
    // and stored in the local storage
    userPortalAuthenticationData = USER_PORTAL_AUTH_DATA_EMPTY;
    localStorage.setItem(
      USER_PORTAL_AUTH_DATA_ITEM,
      JSON.stringify(userPortalAuthenticationData),
    );
  }

  /**
   * Returns the item with the authentication data
   *
   * @returns
   */
  getAuthDataFromLocalStorage(): UserPortalAuthData {
    const userPortalAuthData: string | null = localStorage.getItem(USER_PORTAL_AUTH_DATA_ITEM);

    /* istanbul ignore if */
    if (!userPortalAuthData) {
      return USER_PORTAL_AUTH_DATA_EMPTY;
    }

    const userPortalAuthDataParsed: UserPortalAuthData =
      JSON.parse(userPortalAuthData);

    return userPortalAuthDataParsed;
  }

  /**
   * Sets the the object in the User Portal Authentication Data in the
   * local storage
   *
   * @param newAuthData
   */
  setNewAuthenticationDataObjectInLocalStorage(
    newAuthData: UserPortalAuthData,
  ): void {
    localStorage.setItem(
      USER_PORTAL_AUTH_DATA_ITEM,
      JSON.stringify(newAuthData),
    );
  }

  login(username?: string, schema?: string): void {
    if (username || schema) {
      const options: AuthOptions | undefined = {
        customParams: {
          login_hint: username ? username : '',
          scheme: schema ? schema : ''
        },
      };
      this.oidcSecurityService.authorize(undefined, options);
    } else {
      this.oidcSecurityService.authorize();
    }
  }

  logout(): void {
    const userPortalAuthData = this.getAuthDataFromLocalStorage();
    userPortalAuthData.expirationDate = null;
    userPortalAuthData.sessionStatedAt = null;
    userPortalAuthData.sessionEndedAt = new Date();
    this.setNewAuthenticationDataObjectInLocalStorage(userPortalAuthData);
    this.errorHandleService.deleteRbsErrorFromLocalStorage();
    cleanSessionStorage(['tempOrgs', 'callback', 'land_ref']);
    cleanLocalStorage(['temp_ref_data_callback'])
    this.oidcSecurityService.logoff().subscribe();
  }

  logoutLocal(): void {
    const userPortalAuthData = this.getAuthDataFromLocalStorage();
    userPortalAuthData.expirationDate = null;
    userPortalAuthData.sessionStatedAt = null;
    userPortalAuthData.sessionEndedAt = new Date();
    this.setNewAuthenticationDataObjectInLocalStorage(userPortalAuthData);
    this.errorHandleService.deleteRbsErrorFromLocalStorage();
    cleanSessionStorage(['tempOrgs', 'callback', 'land_ref']);
    cleanLocalStorage(['temp_ref_data_callback'])
    this.oidcSecurityService.logoffLocal();
  }

  getUserDataFromFortify(): void {
    this.isAuthenticated$
      .pipe(
        concatMap(validToken => {
          if (validToken) {
            return forkJoin({
              idTokenPayload: this.oidcSecurityService.getPayloadFromIdToken(),
              accessTokenPayload:
                this.oidcSecurityService.getPayloadFromAccessToken(),
            });
          } else {
            return of(null);
          }
        }),
      )
      .subscribe({
        next: response => {
          this.userId.next(response?.idTokenPayload.sub);
          this.fortifyRoles.next(
            response?.idTokenPayload.role || 'NO FORTIFY ROLE',
          );
          this.canUseSupportToolsSubject.next(
            response?.accessTokenPayload.CanUseSupportTools === 'true',
          );
        },
      });
  }

  validDomainEmail(email: string): boolean {
    if (
      email.toLowerCase().includes('@lcptracker.com') ||
      email.toLowerCase().includes('@cikume.com')
    ) {
      return true;
    } else {
      return false;
    }
  }

  /**
  * Checks if a user's IP is in the company IP whitelist
  * @returns if a user's IP whitelisted
  */
  isUserIpInTheWhitelist(): Observable<boolean> {
    return this.http
      .get<any>(`${environment.applications.fortify.api}/IPPolicy/IsClientCompliant`)
      .pipe(
        map(response => response.data),
        catchError(error => {
          const errorRejected = this.errorHandleService.serverErrorResponse('Fortify', 'isUserIpInTheWhitelist', error);
          console.error(errorRejected);
          // Case Error, Return False
          return of(false);
        })
      );
  }

  /************************************************************************************************************
   *
   * Checks if a user exists in TrackerPro
   * @returns Observable<LcpTracker>
   */
  checkUserExistsTrackerPro(email: string): Observable<LcpTracker> {
    return this.http.post<LcpTracker>(this.urlTrackerApi, { id: email })
  }

  /**
   * Checks if a user exists in Certified
   * @returns Observale<Certified>
   */
  checkUserExistsCertified(email: string): Observable<Certified> {
    return this.http.post<Certified>(this.urlCertifiedApi, { id: email });
  }

  /**
   * angular-auth-oidc-client Managed Services Below
   */
  authorize(): void {
    this.oidcSecurityService.authorize();
  }

  checkAuth(): Observable<LoginResponse> {
    return this.oidcSecurityService.checkAuth();
  }

  /**
   * This method is used to force a refresh of the current session.
   * It uses the 'forceRefreshSession' method from the 'oidcSecurityService' service.
   *
   * @returns An Observable of any type. The actual type depends on what 'forceRefreshSession' method returns.
   */
  forceRefreshSession$(): Observable<LoginResponse> {
    return this.oidcSecurityService.forceRefreshSession().pipe(
      map(result => result)
    );
  }

  /* istanbul ignore next */
  //login with the variable schema per default using external provider **redirect**
  loginWithProvider(schema: string): void {
    const url =
      environment.applications.fortify.v2 + `/Account/${schema}/Login`;
    window.location.href = url;
  }

  /* istanbul ignore next */
  findRole(userData: UserProfileData): any {
    // Extracting roles from the user data
    const roles = userData.usersInOrg.reduce((acc: string[], userOrg: any) => {
      const userRoles = userOrg.orgUsersForOrgRole.map((orgRole: any) => orgRole.orgRole.roles.name);
      return [...acc, ...userRoles];
    }, []);

    // Creating a BehaviorSubject to store the filtered roles
    this.rbsRoles.next(roles || 'NO RBS ROLE');
  }
}
